(function ($) {
  $navs = $('.mod_navigation .level_2, .metanav, .search__container, .mod_changelanguage');

  $('.menu__toggle').click(function () {
    $(this).toggleClass('active');
    $('#header').toggleClass('active');
    $('#header nav').toggleClass('active');
  });
  if (window.matchMedia("(max-width: 480px)").matches) {
    $('.dropdown-toggle').click(function () {
      $(this).parent().toggleClass('active');
      $(this).parent().find('.dropdown-menu').stop().slideToggle();
      return false;
    });
  }

  $(document).ready(function () {
    if ($('.level_2 > li.active').length) {
      $l2 = $('.level_2 > li.active');
    }
  });

  $(document).ready(function () {
    $('.widget-select .btn.dropdown-toggle, .widget-select div.dropdown-menu li').click(function () {
      $(this).toggleClass('active');
      $('.widget-select div.dropdown-menu').toggleClass('open');
    });

  });
  if (window.matchMedia("(min-width: 769px)").matches) {
    $('header').mouseover(function () {
      if (!$('.mod_onepagewebsitenavigation, .news_nav').length || !$('.mod_onepagewebsitenavigation, .news_nav').is(':hover')) {
        $('header').addClass('hover');
        $('.mod_onepagewebsitenavigation, .news_nav').stop().hide();
        $navs.stop().slideDown(200);
      }
    });
    $('header').mouseleave(function () {
      $('header').removeClass('hover');
      $navs.stop().slideUp(200, function () {
        $('.mod_onepagewebsitenavigation, .news_nav').stop().fadeIn();
      });

    });
  }
  $(window).scroll(function () {
    if ($(window).scrollTop() > $('header').height()) {
      $('header').addClass('scrolled');
    } else {
      $('header').removeClass('scrolled');
    }
  });
  $(window).resize(function () {
    resizeHeader()
  });


  function resizeHeader () {
    var $logowidth = $('.logo.img').outerWidth() * 2;
    var $logomargin = parseFloat($('.logo').css('left'));
    if ($(window).width() > ($logowidth + 1440)) {
      $('#header').removeClass('resize');
    } else {
      $('#header').addClass('resize');
    }
  }

  $('#header').addClass('resize');

  $('.search__button').click(function () {
    if ($(this).parent().find('input[type="search"]').val() != '') {
      $('.search__container form').submit();
    }
  });

})(jQuery);











