(function ($) {
  var scrollCheck = 0;
  if (window.matchMedia("(max-width: 481px)").matches) {

  } else {
    var lastScrollTop = 0;
    var scrolldelay = 150;
    $header = $('#header');
    $onepagenav = $('.mod_onepagewebsitenavigation');

    $link = $onepagenav.find('li a');
    $(window).scroll(function (event) {
      var st = $(this).scrollTop();
      if (st > ($header.height() / 4)) {
        $header.addClass('fixed');
        $header.addClass('transitioned');
        if (st > lastScrollTop) {
          $header.removeClass('out');

        } else {
          $header.addClass('out');
        }
        lastScrollTop = st;
      } else if (st == 0) {
        $header.removeClass('fixed').removeClass('out').removeClass('transitioned');
      }
      if (scrollCheck === 0) {
        $('li.page').each(function () {
          $this = $(this);
          $offsettop = $this.offset().top;

          if (($offsettop - $header.height() - 100) < st) {
            $id = $this.prop('id');
            $onepagenav.find('li').removeClass('active');
            $onepagenav.find('li a').each(function () {
              $href = $(this).prop('href').split('#')[1];
              if ($id == $href) {
                $(this).parent().addClass('active');
              }
            });
          }
        });
      }
    });

    $link.click(function () {
      scrollCheck = 1;
      $('header').removeClass('hover');
      $link.parent('li').removeClass('active');
      $(this).parent('li').addClass('active');
      $href = $(this).prop('href').split('#')[1];
      $('html, body').animate({scrollTop: $('#' + $href).offset().top - $header.height()},
      function () {
        scrollCheck = 0;
      });

      return false;
    });
    $(document).ready(function () {
      $onepagenav.find('li').eq(0).addClass('active');
    });
  }


})
(jQuery);
