(function ($) {
    $('.accordion').each(function () {
        var $this = $(this);
        $headline = $this.find('.accordion__headline');
        $link = $this.find('.accordion__toggle');
        $headline.click(function () {
            $(this).parent().parent().toggleClass('accordion--active');
            $(this).parent().find('.accordion__description').stop().slideToggle();
            $(this).parent().find('.accordion__contact').stop().slideToggle();
        });
        $link.click(function () {
            $this.toggleClass('accordion--active');
            $this.find('.press__description').stop().slideToggle();
            var text = $(this).text();
            $(this).text(
                text == "Mehr erfahren" ? "schließen" : "Mehr erfahren");
            return false;
        });
    });

})(jQuery);











